<template>
    <edit-user-modal
        :modal="modal"
        @closeModal="closeModal"
        @closeModalAndFetch="closeModal"
    />
    <back-button :route="{ name: 'compliance' }" />

    <user-heading v-if="storeCompliance" :user="storeCompliance"></user-heading>

    <div class="grid grid-cols-3 gap-4">
        <div class="col-span-3">
            <div class="grid grid-cols-3 gap-4" v-if="storeCompliance">
                <div class="col-span-2">
                    <div
                        v-if="
                            !storeCompliance.email_verified_at &&
                            !storeCompliance.legacy_id
                        "
                        class="bg-indigo-900 py-4 text-center lg:px-4"
                    >
                        <div
                            class="flex items-center bg-indigo-800 p-2 leading-none text-indigo-100 lg:inline-flex lg:rounded-full"
                            role="alert"
                        >
                            <span
                                class="mr-3 flex rounded-full bg-red-500 px-2 py-1 text-xs font-bold uppercase"
                                >Aviso</span
                            >
                            <span class="mr-2 flex-auto text-left font-semibold"
                                >Este usuário não verificou o e-mail.</span
                            >
                        </div>
                    </div>

                    <h1
                        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold uppercase text-gray-400"
                    >
                        <span class="mt-2"
                            >USUÁRIO #{{ storeCompliance.id }}</span
                        >
                        <button
                            v-if="hasPermission('update.users')"
                            @click.prevent="modal = !modal"
                            :disabled="loading"
                            type="button"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            Editar
                        </button>
                    </h1>

                    <div
                        class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
                    >
                        <div
                            v-if="storeCompliance"
                            class="m-4 mb-4 grid grid-cols-3 gap-3"
                        >
                            <info-col title="NOME">
                                {{ storeCompliance.name }}
                            </info-col>

                            <info-col title="E-MAIL">
                                {{ storeCompliance.email }}
                            </info-col>

                            <info-col title="TELEFONE">
                                {{ storeCompliance.formatted_phone }}
                            </info-col>

                            <info-col title="NATUREZA">
                                {{ storeCompliance.formatted_type }}
                            </info-col>

                            <info-col
                                title="NOME SOCIAL"
                                v-if="storeCompliance.type == 'company'"
                            >
                                {{ storeCompliance.social_name ?? "-" }}
                            </info-col>

                            <info-col
                                title="CNPJ"
                                v-if="storeCompliance.type == 'company'"
                            >
                                {{ formatCnpj(storeCompliance.cnpj) }}
                            </info-col>

                            <info-col
                                title="CPF"
                                v-if="storeCompliance.type == 'person'"
                            >
                                {{ formatCpf(storeCompliance.cpf) }}
                            </info-col>

                            <info-col
                                title="ANIVERSÁRIO"
                                v-if="storeCompliance.type == 'person'"
                            >
                                {{ storeCompliance.birth }}
                            </info-col>

                            <info-col
                                title="SITE"
                                v-if="storeCompliance.website"
                            >
                                {{ storeCompliance.website }}
                            </info-col>

                            <info-col
                                v-if="storeCompliance.about"
                                title="SOBRE"
                                class="col-span-3 mb-4 rounded bg-gray-200 p-3 dark:bg-slate-900"
                            >
                                {{ storeCompliance.about }}
                            </info-col>

                            <info-col
                                v-if="storeCompliance.query_params_created"
                                title="Parâmetros Utilizados na URL no Cadastro"
                                class="col-span-3 mb-4 rounded bg-gray-200 p-3 dark:bg-slate-900"
                            >
                                {{ storeCompliance.query_params_created }}
                            </info-col>

                            <info-col title="EMAIL VERIFICADO">
                                <img
                                    v-if="storeCompliance.email_verified_at"
                                    src="https://www.svgrepo.com/show/410398/check.svg"
                                    alt="check"
                                    style="max-width: 15px"
                                />
                                <img
                                    v-else
                                    src="https://www.svgrepo.com/show/151290/close.svg"
                                    alt="close"
                                    style="max-width: 15px"
                                />
                            </info-col>

                            <info-col
                                title="EMANCIPADO"
                                v-if="storeCompliance.is_emancipated"
                            >
                                <img
                                    src="https://www.svgrepo.com/show/410398/check.svg"
                                    alt="check"
                                    style="max-width: 15px"
                                />
                            </info-col>

                            <div
                                v-if="storeCompliance.type == 'company'"
                                class="col-span-3 grid grid-cols-3 gap-3 rounded bg-gray-200 p-3 dark:bg-slate-900"
                            >
                                <info-col
                                    title="SÓCIO ADMINISTRADOR"
                                    class="col-span-3"
                                />
                                <info-col title="CPF">
                                    {{
                                        formatCpf(
                                            storeCompliance.company_partner
                                                ?.cpf ??
                                                storeCompliance.company_partner_cpf ??
                                                storeCompliance.cpf,
                                        )
                                    }}
                                </info-col>
                                <info-col title="NOME">
                                    {{
                                        storeCompliance.company_partner?.name ??
                                        storeCompliance.name
                                    }}
                                </info-col>
                                <info-col title="ANIVERSÁRIO">
                                    {{
                                        formatDateWithoutTime(
                                            storeCompliance.company_partner
                                                ?.birth ??
                                                storeCompliance.birth,
                                        )
                                    }}
                                </info-col>
                            </div>
                        </div>
                    </div>

                    <h1
                        v-if="storeCompliance?.addresses?.length > 0"
                        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold uppercase text-gray-400"
                    >
                        <span class="mt-2">{{
                            storeCompliance?.addresses?.length == 1
                                ? "ENDEREÇO"
                                : "ENDEREÇOS"
                        }}</span>
                    </h1>

                    <div
                        v-for="(address, index) in storeCompliance.addresses"
                        v-bind:key="address.id"
                        class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
                    >
                        <div
                            class="m-4 mb-4 grid grid-cols-3 gap-3"
                            v-if="index === 0"
                        >
                            <info-col title="RUA">
                                {{ address.street }}
                            </info-col>

                            <info-col title="Nº">
                                {{ address.street_number }}
                            </info-col>

                            <info-col title="BAIRRO">
                                {{ address.neighborhood }}
                            </info-col>

                            <info-col title="CEP">
                                {{ address.zip_code }}
                            </info-col>

                            <info-col
                                v-if="address.complement"
                                title="COMPLEMENTO"
                            >
                                {{ address.complement }}
                            </info-col>

                            <info-col
                                v-if="address.reference"
                                title="REFERÊNCIA"
                            >
                                {{ address.reference }}
                            </info-col>

                            <info-col v-if="address.reference" title="CIDADE">
                                {{ address.city }}
                            </info-col>

                            <info-col title="ESTADO">
                                {{ address.state }}
                            </info-col>

                            <info-col title="PAÍS">
                                {{ address.country }}
                            </info-col>
                        </div>
                    </div>
                </div>
                <div>
                    <h1
                        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold uppercase text-gray-400"
                    >
                        <span class="invisible mt-2">Usuário</span>
                    </h1>

                    <div
                        class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                    >
                        <div
                            class="flex w-full"
                            role="status"
                            v-if="loading && statuses.length < 1"
                        >
                            <svg
                                aria-hidden="true"
                                class="mx-auto h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        <ol
                            v-else
                            class="relative w-full border-l border-gray-200 dark:border-gray-700"
                        >
                            <li class="mb-10 ml-4" v-for="status in statuses">
                                <div
                                    class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white dark:border-gray-900"
                                    :class="{
                                        'bg-green-500': [
                                            'approved',
                                            'onboarding-approved',
                                        ].includes(status.status),
                                        'bg-red-500': [
                                            'reproved',
                                            'onboarding-reproved',
                                        ].includes(status.status),
                                        'bg-yellow-500': [
                                            'pending',
                                            'pending-correction',
                                            'onboarding-pending-correction',
                                            'onboarding-waiting',
                                            'onboarding-pending',
                                        ].includes(status.status),
                                    }"
                                ></div>
                                <time
                                    class="mb-1 flex items-center text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                                >
                                    {{ status.created_at }}
                                    <user-status-modal
                                        v-if="status.checks"
                                        :status="status"
                                    ></user-status-modal>
                                </time>
                                <h3
                                    class="text-lg font-semibold text-gray-900 dark:text-white"
                                >
                                    {{ getStatusName(status?.status) }}
                                </h3>
                                <p
                                    class="text-base font-normal text-gray-500 dark:text-gray-400"
                                >
                                    {{ status?.user?.name }}
                                    {{ status?.user?.email }}
                                </p>
                                <p
                                    v-if="
                                        status?.description &&
                                        status?.status !== 'approved'
                                    "
                                    class="mt-1 rounded-lg bg-gray-400 p-4 text-base font-normal text-white text-white dark:bg-slate-900 dark:text-gray-400"
                                >
                                    {{ status?.description }}
                                </p>
                            </li>
                            <li class="ml-4">
                                <div
                                    class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700"
                                ></div>
                                <time
                                    class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                                >
                                    {{ storeCompliance.created_at }}
                                </time>
                                <h3
                                    class="text-lg font-semibold text-gray-900 dark:text-white"
                                >
                                    Conta Criada
                                </h3>
                                <p
                                    class="text-base font-normal text-gray-500 dark:text-gray-400"
                                >
                                    <a
                                        @click="approve = !approve"
                                        class="inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                                    >
                                        Aprovar/Reprovar
                                        <svg
                                            class="ml-2 h-3 w-3"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </a>
                                </p>
                            </li>
                        </ol>
                    </div>
                    <TransitionRoot
                        appear
                        :show="approve"
                        enter="transition-opacity duration-75"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="transition-opacity duration-150"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <div
                            class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                        >
                            <h3
                                class="text-lg font-semibold text-gray-900 dark:text-white"
                            >
                                Aprovar/Reprovar
                            </h3>
                            <label
                                for="countries"
                                class="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
                                >Selecione uma opção</label
                            >
                            <select
                                v-model="form.status"
                                id="countries"
                                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            >
                                <option :value="false" selected>Status</option>
                                <option value="onboarding-waiting">
                                    Aguardando Onboarding
                                </option>
                                <option value="onboarding-pending">
                                    Onboarding Pendente
                                </option>
                                <option value="onboarding-approved">
                                    Onboarding Aprovado
                                </option>
                                <option value="onboarding-reproved">
                                    Onboarding Reprovado
                                </option>
                                <option value="onboarding-pending-correction">
                                    Onboarding Pendente de Correção
                                </option>
                                <option value="approved">Aprovado</option>
                                <option value="reproved">Reprovado</option>
                                <option value="pending-correction">
                                    Pendente de Correção
                                </option>
                            </select>

                            <div
                                v-if="
                                    form.status === 'reproved' ||
                                    form.status === 'pending-correction'
                                "
                            >
                                <ReasonSelect
                                    reasonType="user"
                                    @reason-selected="handleReasonSelected"
                                />
                            </div>

                            <button
                                v-if="form.status"
                                @click.prevent="submit"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="mx-auto mt-5 inline-flex w-full items-center justify-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                <svg
                                    v-if="loading"
                                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Enviar
                            </button>
                        </div>
                    </TransitionRoot>
                </div>

                <div class="col-span-4">
                    <user-documents
                        :statuses="statuses"
                        :user="storeCompliance"
                        v-if="storeCompliance && statuses"
                        @update-statuses="fetchStatus"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
import { mapActions, mapWritableState } from "pinia";
import { useToast } from "vue-toastification";
import EditUserModal from "../../components/compliance/EditUserModal.vue";
import InfoCol from "../../components/layouts/InfoCol.vue";
import ReasonSelect from "../../components/layouts/ReasonSelect.vue";
import BackButton from "../../components/layouts/navigation/BackButton.vue";
import UserDocuments from "../../components/user/UserDocuments.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
import { Store } from "../../stores/store.js";
import UserHeading from "../users/UserHeading.vue";
import UserStatusModal from "./UserStatusModal.vue";
export default {
    components: {
        ReasonSelect,
        BackButton,
        UserDocuments,
        InfoCol,
        UserStatusModal,
        ArrowPathIcon,
        UserHeading,
        TransitionRoot,
        EditUserModal,
    },
    mixins: [HelperMixin, PermissionMixin],
    data() {
        return {
            modal: false,
            approve: false,
            loading: false,
            documents: [],
            statuses: [],
            form: {
                status: false,
                description: "",
            },
        };
    },

    computed: {
        hasPendingCorrection() {
            if (this.statuses.length < 1) {
                return false;
            }

            return this.statuses.at(0).status === "pending-correction";
        },
        ...mapWritableState(Store, ["storeCompliance"]),
    },

    mounted() {
        this.fetchCompliance().then(() => {
            this.fetchStatus();
        });
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    methods: {
        ...mapActions(Store, ["fetchCompliance"]),
        closeModal() {
            this.modal = false;
        },
        fetchStatus() {
            axios
                .get("/api/user/" + this.storeCompliance.id + "/status")
                .then(({ data }) => {
                    this.statuses = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.toast.error(error.response.data.message);
                });
        },
        submit() {
            this.loading = true;
            axios
                .post(
                    "/api/user/" + this.storeCompliance.id + "/status",
                    this.form,
                )
                .then(({ data }) => {
                    this.fetchCompliance().then(() => this.fetchStatus());
                    this.approve = false;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    this.toast.error(response.data.message);
                });
        },
        handleReasonSelected(reason) {
            this.form.description = reason;
        },
    },
};
</script>
