<script>
export default {
    methods: {
        getCommissionTypeColor(type) {
            switch (type) {
                case "ticto":
                    return "bg-blue-500 text-white";
                case "affiliate":
                    return "bg-green-500 text-white";
                case "manager":
                    return "bg-yellow-500 text-white";
                case "coproducer":
                    return "bg-purple-500 text-white";
                case "provider":
                    return "bg-red-500 text-white";
                case "referral":
                    return "bg-indigo-500 text-white";
                case "producer":
                    return "bg-pink-500 text-white";
                case "cashback":
                    return "bg-orange-500 text-white";
                default:
                    return "";
            }
        },
        formatCommissionType(type) {
            switch (type) {
                case "ticto":
                    return "Ticto";
                case "affiliate":
                    return "Afiliado";
                case "manager":
                    return "Coprodutor";
                case "coproducer":
                    return "Coprodutor";
                case "provider":
                    return "Fornecedor";
                case "referral":
                    return "Indicação";
                case "producer":
                    return "Produtor";
                case "cashback":
                    return "Cashback";
                default:
                    return "Venda Padrão";
            }
        },
    },
};
</script>
