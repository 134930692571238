<template>
    <div class="flex flex-col">
        <table-component
            v-if="devices"
            :loading="loading"
            :pagination="devices"
            :headers="[
                'Id',
                'Usuários',
                'Ativo em:',
                'Ip',
                'Localização',
                'Dispositivo',
                'Navegador',
            ]"
            @fetch="fetch"
        >
            <template v-for="device in devices.data" :key="device.visitor_id">
                <tr>
                    <td class="table-cell-sm">
                        {{ device.visitor_id }}
                    </td>
                    <td class="table-cell-sm">
                        <div
                            class="flex flex-col space-y-1 divide-y divide-gray-700"
                        >
                            <router-link
                                v-for="relatedUser in device.related_users"
                                :key="relatedUser.id"
                                target="_blank"
                                :to="{
                                    name: 'user.metrics',
                                    params: { user_id: relatedUser.id },
                                }"
                                class="flex w-full items-center text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-600"
                            >
                                <span
                                    class="mr-1 max-w-[100px] truncate"
                                    v-tooltip="relatedUser.email"
                                >
                                    {{ relatedUser.email }}
                                </span>
                                {{ relatedUser.score }}
                            </router-link>
                        </div>
                    </td>
                    <td class="table-cell-sm">
                        {{ device.last_activity }}
                    </td>
                    <td class="table-cell-sm">
                        {{ device.ip }}
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2" v-if="device.location">
                            {{ device.location?.country?.code }}
                            {{ device.location?.subdivisions[0]?.isoCode }}
                            {{ device.location?.city?.name }}
                        </div>
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2">
                            <os-icon
                                :os="device.platform.name"
                                v-tooltip="device.platform.name"
                            />
                            <span>
                                {{ device.platform.version }}
                            </span>
                        </div>
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2">
                            <browser-icon
                                :browser="device.browser.name"
                                v-tooltip="device.browser.name"
                            />
                            <span>{{ device.browser.version }}</span>
                        </div>
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import OsIcon from "../../layouts/antifraud/OsIcon.vue";
import BrowserIcon from "../../layouts/antifraud/BrowserIcon.vue";
import TableComponent from "../../layouts/table/TableComponent.vue";
import DeviceModal from "./DeviceModal.vue";

export default {
    components: { DeviceModal, TableComponent, BrowserIcon, OsIcon },
    props: ["user"],
    data() {
        return {
            selected: false,
            loading: false,
            devices: [],
            pagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get("/api/user/" + this.user.id + "/devices", {
                    params: {
                        page: this.pagination.current_page,
                    },
                })
                .then(({ data }) => {
                    this.devices = data;
                    this.loading = false;
                });
        },
    },
};
</script>
